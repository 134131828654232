<!--定时任务-->
<template>
    <div class="account-model">
        <div class="global-view-table">
            <div class="table-header">
                <el-button class="right-operation" type="primary" @click="toAdd()">添加定时任务</el-button>
            </div>

            <el-table v-loading="tableLoad" border :data="tableData" size="mini">
                <el-table-column prop="jobGroup" label="任务组" width="100"/>
                <el-table-column prop="jobBean" label="任务Bean" width="200" show-overflow-tooltip/>
                <el-table-column prop="jobName" label="任务名称" width="200" show-overflow-tooltip/>
                <el-table-column prop="jobCron" label="Cron表达式" width="145"/>
                <el-table-column prop="executeIp" label="可执行ip地址, JSONArray格式" width="220"/>
                <el-table-column prop="dataVersion" label="版本" width="100"/>
                <el-table-column prop="addTime" label="添加时间" width="145"/>
                <el-table-column prop="updateTime" label="修改时间" width="145"/>
                <el-table-column prop="status" label="状态" width="100">
                    <template slot-scope="{ row }">
                        <el-switch
                                v-model="row.status"
                                :active-value="true"
                                :inactive-value="false"
                                disabled
                        />
                        <el-link
                                :underline="false"
                                :type="row.status ? 'primary' : ''"
                                class="status-label"
                        >&nbsp;&nbsp;{{
                            row.status ? "启用" : "禁用"
                            }}
                        </el-link
                        >
                    </template>
                </el-table-column>
                <el-table-column prop="operation" label="操作" width="80" fixed="right">
                    <template slot-scope="{ row }">
                        <el-button size="small" @click="toDetail(row)">
                            编辑
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <GPagination
                    :total="total"
                    :current-page="pageInfo.pageNum"
                    :size="pageInfo.pageSize"
                    @change="changePage"
            />
        </div>
    </div>
</template>

<script>
    import {
        getJobConfigList
    } from "@/api/business";

    import GPagination from "@/components/GPagination";
    import {filterFormat} from '@/utils/tools'

    export default {
        name: "account-list",
        components: {
            GPagination,
        },
        data() {
            return {
                tableLoad: false,
                tableData: [],
                total: 0,
                pageInfo: {
                    pageNum: 1,
                    pageSize: 20,
                },
                filterInfo: {
                    configCode: '',
                }
            };
        },
        created() {
            this.search(this.pageInfo);
        },
        methods: {
            toAdd() {
                this.$router.push({path: '/timerDetail'})
            },

            del(row) {
                this.$messageBox
                    .confirm("删除后，数据不可恢复。是否确认删除？", "确认删除", {
                        confirmButtonText: "确认",
                        cancelButtonText: "取消",
                        type: "error",
                    }).then(() => {
                    delResConfig({
                        id: row.id,
                    }).then(() => {
                        this.$message.success("删除成功");
                        this.search({pageNum: 1, pageSize: this.pageInfo.pageSize});
                    });
                })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除",
                        });
                    });
            },
            changePage(page) {
                this.search(page);
            },
            toDetail(row) {
                this.$router.push({path: '/timerDetail', query: {id: row.id}})
            },


            search(page) {
                Object.assign(this.pageInfo, page);
                this.tableLoad = true;
                getJobConfigList({
                    ...this.pageInfo,
                    ...filterFormat(this.filterInfo),
                })
                    .then((res) => {
                        const {total, records} = res.data;
                        this.tableData = records;
                        this.total = total;
                        this.tableLoad = false;
                    })
                    .catch(() => {
                        this.tableLoad = false;
                    });
            }
        },
    };
</script>

<style lang="scss">
    .account-model {
        width: 100%;
        height: 100%;
        padding: 16px;
        overflow-y: auto;
        .global-view-table {
            height: auto;
            margin-top: 16px;
            padding: 16px;
        }
        .role-tag {
            margin-right: 8px;
        }
    }

    .account-dialog {
        .el-dialog__body {
            padding: 24px 24px 0;
        }
    }
</style>
